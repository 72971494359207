import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../../store";
import PageLayout from "../../components/PageLayout";
import {Route, RouteComponentProps} from "react-router";
import {fetchProject, react, setStatus} from "../../data/projects/service";
import {colors} from "../../theme";
import {Button} from "@material-ui/core";
import Section from "../../components/Section";
import FlexSection from "../../components/FlexSection";
import Thumbs from "../../components/Thumbs";
import {PROJECT_BASE_ROUTE} from "../../lib/routes";
import Team from "./components/Team";
import {ProjectReaction} from "../../data/projects/types";
import ProjectModeration from "../../components/ProjectModeration";
import {OrderedProjectsList} from "../../data/projects";
import {Link} from "react-router-dom";
import {PiNetworkClient} from "../../data/auth";



interface Props extends RouteComponentProps<{id: string}> {
  orderedList: OrderedProjectsList,
  fetchProject: (id: string) => void,
  react: (id: string, reaction: ProjectReaction) => void,
  setStatus: (id: string, status: string) => void,
}

interface ProjectState {
}

class Project extends React.Component<Props, ProjectState> {
  constructor(props: Props) {
    super(props);

  }

  componentDidMount(): void {
    this.props.fetchProject(this.props.match.params.id);
  }

  onThumbClicked = (type: ProjectReaction) => {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return;

    this.props.react(project.id, type);
  }

  renderProjectFundingSection = () => {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) {
      return null;
    }

    if (project.status !== "published") {
      return null;
    }


    return (
      <Section>
        <h4>Pi Donations to the project</h4>
        <FlexSection style={{marginTop: 0}}>
          <p style={{color: colors.primary}}>{project.financing} π</p>

          <Button color="secondary" variant="contained" onClick={() => this.props.history.push(`${PROJECT_BASE_ROUTE}/${this.props.match.params.id}/donate`)}>Donate Pi</Button>

        </FlexSection>
        <div style={{fontSize: "0.8em", color: "gray", marginTop: "8px"}}>
          A Pi donation helps incentivize builders to materialize the project, and it will be returned back to its donors if the project never happens.
        </div>
      </Section>
    )
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }


    return (
      <PageLayout title={project.name}>
        <div style={{width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "space-between"}} >
          <Section>
            {project.summary} {" "} <Link to={`${PROJECT_BASE_ROUTE}/${project.id}/description`}>Read more</Link>
          </Section>

          <Section>
            <h4>Team of Pionneers interested in building this</h4>
            <Team project={project} />
          </Section>

          {this.renderProjectFundingSection()}



          <FlexSection>
            <h4>Should this project be built?</h4>
            <Thumbs data={{up: project.votes.up, down: project.votes.down}} onReacted={this.onThumbClicked} myReaction={project.my_reaction}/>
          </FlexSection>

          <ProjectModeration project={project} />

          {
            project.can_user_edit &&
              <FlexSection>
                <div>
                  <h4>Only for editors:</h4>
                </div>
                <div>
                  <Link to={`${PROJECT_BASE_ROUTE}/${project.id}/edition`} style={{textDecoration: "none"}}>
                    <Button variant="outlined" color="primary">Edit</Button>
                  </Link>
                </div>
              </FlexSection>

          }
        </div>
      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  orderedList: state.projects.orderedList
}),
(dispatch: ThunkDispatchArg) => ({
  fetchProject: (id: string) => dispatch(fetchProject(id)),
  react: (id: string, reaction: ProjectReaction) => dispatch(react(id, reaction)),
  setStatus: (id: string, status: string) => dispatch(setStatus(id, status))
}))(Project);
