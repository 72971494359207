import React from "react";


interface Props {
  children: React.ReactNode,
  style?: React.CSSProperties,
}


export default function Section(props: Props) {

  let style = {
    width: "100%",
    marginTop: "32px",
  } as React.CSSProperties;

  if (props.style) {
    style = {...style, ...props.style}
  }

  return (
    <div style={style}>
      {props.children}
    </div>
  )
}

