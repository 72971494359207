import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { State } from "../store";
import PageLayout from "../components/PageLayout";
import { PROJECTS_LIST_ROUTE } from "../lib/routes";
import Button from "@material-ui/core/Button";
import {get} from "../lib/api";

interface Props extends RouteComponentProps {

}

interface LandingState {
  text: string,
  loaded: boolean,
}


class Landing extends React.Component<Props, LandingState>{
  constructor(props: Props) {
    super(props);
    this.state = {
      text: "",
      loaded: false,
    }
  }

  async componentDidMount() {
    const landingPageResponse = await get("/landing?name=landing");
    this.setState({loaded: true, text: landingPageResponse.text})
  }

  onContinueClicked = () => {
    this.props.history.replace(PROJECTS_LIST_ROUTE);
  }

  renderHTMLText = (htmlBody: string) => {
    return {
      __html: htmlBody
    };
  }

  renderBody = () => {
    if (!this.state.loaded) {
      return <div>Loading landing page...</div>;
    }

    return <div style={{display: "block", alignSelf: "normal"}} dangerouslySetInnerHTML={this.renderHTMLText(this.state.text)} />;
  }

  render() {
    return (
      <PageLayout backgroundColor="#EFEFEF">
        {
          this.renderBody()
        }

        <div style={{display: "block", height: "56px"}} />

        <div style={{ textAlign: "center", position: "fixed", bottom: 0, padding: "8px 0", backgroundColor: "white", width: "100%"}}>
          <Button color="primary" variant="outlined" onClick={this.onContinueClicked}>Continue</Button>
        </div>
      </PageLayout>
    );
  }
}

export default connect((state: State) => ({

}),
() => ({

}))(Landing);
