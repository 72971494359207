import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {Button, Checkbox, RadioGroup, Radio} from "@material-ui/core";
import InputField from "../components/InputField";
import {ProjectCreationDTO, UserSupportDTO} from "../data/projects/types";
import {post, put} from "../lib/api";
import {RouteComponentProps} from "react-router";
import {PROJECT_BASE_ROUTE} from "../lib/routes";
import Section from "../components/Section";
import FlexSection from "../components/FlexSection";
import {OrderedProjectsList} from "../data/projects";
import AppTypeModal from "../components/AppTypesModal";



interface Props extends RouteComponentProps<{id?: string}> {
  orderedList: OrderedProjectsList,
}

interface ProposeProjectState {
  name: string,
  summary: string,
  description: string,
  type: number,
  willBuildApp: boolean,
  howAmIGonnaDo: string,
  ideaSharingConsent: boolean,
  support: {
    leadership: boolean,
    business: boolean,
    technical: boolean,
  },
  appTypesModalOpen: boolean,
}

class ProposeProject extends React.Component<Props, ProposeProjectState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      summary: '',
      description: '',
      type: 0,
      willBuildApp: false,
      ideaSharingConsent: false,
      support: {
        leadership: false,
        business: false,
        technical: false,
      },
      howAmIGonnaDo: "",
      appTypesModalOpen: false,
    }
  }

  componentDidMount(): void {
    console.log("So !", this.props.match.params.id)
    if (!this.props.match.params.id) {
      return;
    }

    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return this.props.history.goBack();
    }


    const currentProjectState = {
      name: project.name,
      summary: project.summary,
      description: project.description,
      type: project.type,
      willBuildApp: project.will_user_build_app,
      ideaSharingConsent: project.idea_sharing_consent,
      support: {
        leadership: project.user_support.indexOf("leadership") !== -1,
        business: project.user_support.indexOf("business") !== -1,
        technical: project.user_support.indexOf("technical") !== -1,
      },
      howAmIGonnaDo: project.user_plan,
    }

    this.setState(currentProjectState)


  }

  isFormValid = () => {
    if (!this.state.ideaSharingConsent) {
      return false
    }

    if (this.state.name === '' || this.state.summary === '' || this.state.description === '' || this.state.type === 0) {
      return false;
    }

    if (!this.state.willBuildApp) {
      return true;
    }

    if (!this.state.support.leadership && !this.state.support.business && !this.state.support.technical) {
      return false;
    }

    if (this.state.howAmIGonnaDo === "") {
      return false;
    }

    return true
  }

  onClick = async () => {
    const user_support = Object.keys(this.state.support).filter((key: string) => this.state.support[key as keyof UserSupportDTO]);

    let projectCreationDTO: ProjectCreationDTO = {
      name: this.state.name,
      summary: this.state.summary,
      description: this.state.description,
      type: this.state.type,
      will_user_build_app: this.state.willBuildApp,
      user_plan: this.state.howAmIGonnaDo,
      user_support: user_support,
      idea_sharing_consent: this.state.ideaSharingConsent,
    };

    if (this.props.match.params.id) {
      projectCreationDTO.id = this.props.match.params.id

      try {
        const response = await put(`/projects/${projectCreationDTO.id}`, {project: projectCreationDTO});
        this.props.history.goBack();
      } catch (e) {
        // TODO: Error handling
        console.log("ERROR WHILE CREATING PROJECT: ", e);
      }
      return;
    }

    try {
      const response = await post('/projects', {project: projectCreationDTO});
      this.props.history.replace(`${PROJECT_BASE_ROUTE}/${response.id}`);
    } catch (e) {
      // TODO: Error handling
      console.log("ERROR WHILE CREATING PROJECT: ", e);
    }
  }

  render() {
    return (
      <PageLayout title="Propose a new project">

        <Section>
          <h4 style={{marginBottom: "8px"}}>Project name</h4>
          <InputField
            value={this.state.name}
            placeholder=""
            maxTextSize={80}
            onChange={(value: string) => { this.setState({name: value}) }}/>
        </Section>

        <Section>
          <h4 style={{marginBottom: "8px"}}>Project summary</h4>
          <InputField
            value={this.state.summary}
            placeholder=""
            height={64}
            maxTextSize={140}
            onChange={(value: string) => { this.setState({summary: value}) }}/>
        </Section>

        <Section>
          <h4 style={{marginBottom: "8px"}}>Project description</h4>
          <InputField
            value={this.state.description}
            placeholder=""
            multilines={true}
            enableEditor={true}
            onChange={(value: string) => { this.setState({description: value}) }}/>
        </Section>

        <Section>
          <FlexSection style={{marginTop: "0px"}}>
            <h4>What type of app is this project?</h4>
            <div style={{padding: "4px", width: "28px", height: "28px", borderRadius: "100%", backgroundColor: "lightgray", textAlign: "center", border: "1px solid gray"}} onClick={() => this.setState({appTypesModalOpen: true})}>
              ?
            </div>
          </FlexSection>
          <div>
            <RadioGroup name="App type">
              <div onClick={() => this.setState({type: 1})}>
                <Radio checked={this.state.type === 1} />Type 1: App with a business model
              </div>
              <div onClick={() => this.setState({type: 2})}>
                <Radio checked={this.state.type === 2} />Type 2: Ecosystem App (no business model necessary)
              </div>
            </RadioGroup>
          </div>
        </Section>

        <Section>
          <h4>Will you build this Pi App project?</h4>
          <div>
            <RadioGroup name="Will I build the app">
              <div onClick={() => this.setState({willBuildApp: false})}>
                <Radio checked={!this.state.willBuildApp} />No. I am just providing an idea.
              </div>
              <div onClick={() => this.setState({willBuildApp: true})}>
                <Radio checked={this.state.willBuildApp} />Yes
              </div>
            </RadioGroup>
          </div>
        </Section>

        {
          this.state.willBuildApp &&
            <>
              <Section>
                <h4>Which kind of support will you provide to build the project?</h4>
                <div>
                  <div onClick={() => this.setState({support: {...this.state.support, leadership: !this.state.support.leadership}})}>
                    <Checkbox checked={this.state.support.leadership} /> Leadership
                  </div>
                  <div onClick={() => this.setState({support: {...this.state.support, business: !this.state.support.business}})}>
                    <Checkbox checked={this.state.support.business} /> Business
                  </div>
                  <div onClick={() => this.setState({support: {...this.state.support, technical: !this.state.support.technical}})}>
                    <Checkbox checked={this.state.support.technical} /> Technical
                  </div>
                </div>
              </Section>

              <Section style={{marginTop: "16px"}}>
                <h4 style={{marginBottom: "8px"}}>Elaborate on how you plan to make this project happen:</h4>
                <InputField
                  value={this.state.howAmIGonnaDo}
                  placeholder=""
                  multilines={true}
                  onChange={(value: string) => { this.setState({howAmIGonnaDo: value}) }}/>
              </Section>
            </>

        }

        <FlexSection>
          <Checkbox checked={this.state.ideaSharingConsent} onClick={() => this.setState({ideaSharingConsent: !this.state.ideaSharingConsent})}/>
          <p>I understand that by making my idea public, others may copy it and/or work on it without me, and I am ok with that. I have not included anything that I consider confidential or proprietary here.</p>
        </FlexSection>




        <div style={{textAlign: "center", marginTop: "32px"}}>
          <Button color="primary" variant="outlined" onClick={this.onClick} disabled={!this.isFormValid()}>Submit</Button>
        </div>


        <p style={{width: "90%", margin: '32px auto'}}>
          Your submission will be reviewed before being published. Please do not submit duplicate projects to the existing ones. If for some reason you need to submit a similar project, please explain the precise reasons why this is different
        </p>

        {
          this.state.appTypesModalOpen && <AppTypeModal onClose={() => this.setState({appTypesModalOpen: false})} />
        }

      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
    orderedList: state.projects.orderedList
  }),
(dispatch: ThunkDispatchArg) => ({
}))(ProposeProject);
