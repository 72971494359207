import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {get} from "../lib/api";


interface Props {
  onClose: () => void,
}
interface AppTypesModalState {
  text: string,
  loaded: boolean,
}


export default class AppTypeModal extends React.Component<Props, AppTypesModalState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      text: "",
      loaded: false,
    }
  }

  async componentDidMount() {
    const landingPageResponse = await get("/landing?name=app_types");
    this.setState({loaded: true, text: landingPageResponse.text})
  }


  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={this.props.onClose}
        classes={{paper: 'ConfirmMessageModal__ModalPaper'}}>
        <DialogTitle>
          <div style={{float: 'right'}}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ padding: 8 }}
              onClick={this.props.onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{paddingTop: 12}}>App types</div>
        </DialogTitle>
        <DialogContent>
          {
            this.state.loaded ?
              <div dangerouslySetInnerHTML={{__html: this.state.text}}></div>
              :
              <p>Loading ...</p>
          }
        </DialogContent>
        <DialogActions style={{flexDirection: 'column'}}>

        </DialogActions>
      </Dialog>
    )
  }
}
