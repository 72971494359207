import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {RouteComponentProps} from "react-router";
import {OrderedProjectsList} from "../data/projects";
import {applyToTeam} from "../data/projects/service";
import {Button} from "@material-ui/core";
import Section from "../components/Section";
import InputField from "../components/InputField";



interface Props extends RouteComponentProps<{id: string, team_id: string}> {
  orderedList: OrderedProjectsList,
  applyToTeam: (projectId: string, team_id: string, skills: string, candidacy: string) => void,
}

interface TeamApplicationState {
  applicationText: string,
  skills: string,
  alreadyApplied: boolean,
}

class TeamApplication extends React.Component<Props, TeamApplicationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      applicationText: "",
      skills: "",
      alreadyApplied: false,
    }
  }

  componentDidMount(): void {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return this.props.history.goBack();

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return this.props.history.goBack();

    const knownCandidacy = project.user_candidacies[team.id];
    if (!knownCandidacy || !knownCandidacy.text) { // No candidacy known, we go though normal process
      return;
    }

    this.setState({applicationText: knownCandidacy.text, skills: knownCandidacy.skills, alreadyApplied: true})
  }

  onJoinTeamClicked = () => {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return;

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return;

    this.props.applyToTeam(project.id, team.id, this.state.skills, this.state.applicationText);
    this.props.history.goBack();
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return <div>Team not found</div>


    return (
      <PageLayout title={project.name}>
        <div style={{fontSize: "1.4em", textAlign: "center", marginTop: "16px"}}>Application - {team.name}</div>


        <Section>
          <h4 style={{marginBottom: "8px"}}>Skills you can provide - Publicly visible</h4>
          <InputField
            value={this.state.skills}
            placeholder="frontend, backend, design, marketing"
            maxTextSize={80}
            onChange={(value: string) => { this.setState({skills: value}) }}/>

        </Section>
        <Section>
          <h4 style={{marginBottom: "8px"}}>How can you help? - Visible to project staff</h4>

          <InputField
            value={this.state.applicationText}
            placeholder=""
            multilines={true}
            maxTextSize={200}
            onChange={(value: string) => { this.setState({applicationText: value}) }}/>

            <div style={{fontSize: "0.8em", color: "gray", marginTop: "16px"}}>
              <p>
                Describe your skills, experience, availability, and the reason why you want to join the team.
              </p>
              <p style={{marginTop: "8px"}}>
                This text will be the only basis on which the project editors decide if they accept your application right now.
              </p>
              <p style={{marginTop: "8px"}}>
                Please do not include personal identifiable information.
              </p>
            </div>
        </Section>

        <Section style={{textAlign: "center"}}>
          <Button variant="outlined" color="primary" onClick={this.onJoinTeamClicked}>
            {this.state.alreadyApplied ? "Update" : "Join"}
          </Button>
        </Section>

      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  orderedList: state.projects.orderedList
}),
(dispatch: ThunkDispatchArg) => ({
  applyToTeam: (projectId: string, team_id: string, skills: string, candidacy: string) => dispatch(applyToTeam(projectId, team_id, skills, candidacy)),
}))(TeamApplication);
