import React from "react";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

interface Props {
  placeholder: string,
  multilines?: boolean,
  height?: number,
  maxTextSize?: number,
  onChange: (value: string) => void,
  value: string,
  enableEditor?: boolean,
}


export default function InputField(props: Props) {
  const onChange = (e: any) => {

    // Forbidding new lines if props do not explicitly allow it.
    if (!props.multilines && e.target.value.indexOf('\n') !== -1) {
      return;
    }

    // Forbidding if text is longer than max size requested in props
    if (props.maxTextSize && e.target.value.length > props.maxTextSize) {
      return
    }

    return props.onChange(e.target.value);
  }

  const onChangeEditor = (value: string) => {
    return props.onChange(value);
  }


  let style: React.CSSProperties = {
    border: "1px solid #dadada",
    width: "100%",
    padding: "4px",
    fontSize: "1em",
    textAlign: "left",
    textAnchor: "start",
  }

  const editorButtons = [
    ['undo', 'redo'],
    ['formatBlock'],
    ['bold', 'underline', 'italic'],
    ['fontColor', 'hiliteColor', 'list'],
    ['outdent', 'indent', 'align'],
    ['removeFormat']
  ]

  if (props.height) {
    style.height = `${props.height}px`
  }

  // By default, the component is a normal text field.
  let component = <input type="text" style={style} placeholder={props.placeholder} onChange={onChange} value={props.value} />;


  // If there is an height but no multiline, we use a textarea in order to get the placeholder at the top
  if (props.height && !props.multilines) {
    style.resize = 'none';

    component = (
      <textarea
        placeholder={props.placeholder}
        style={style}
        value={props.value}
        onChange={onChange}
      />
    )
  }

  if (props.multilines) {
    if (props.enableEditor) {
      component = (
        <SunEditor height="30vh" setDefaultStyle="font-size: 16px;" placeholder={props.placeholder} setContents={props.value} setOptions={{buttonList: editorButtons}} onChange={onChangeEditor} />
      );
    }
    else {
      component = (
        <textarea
          placeholder={props.placeholder}
          style={style}
          rows={5}
          value={props.value}
          onChange={onChange}
        />
      );
    }
  }


  const charCountStyle: React.CSSProperties = {
    width: "100%",
    textAlign: "right",
    fontSize: "0.7em",
    color: "gray",
    marginTop: "4px"
  };

  return (
    <div>
      {component}
      {
        props.maxTextSize &&
        <div style={charCountStyle}> {props.value.length} / {props.maxTextSize} </div>
      }
    </div>
  )

}

