import React from "react";
import {CandidacyDTO} from "../data/projects/types";
import FlexSection from "./FlexSection";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import {fetchProject, setCandidacyStatus} from "../data/projects/service";
import Section from "./Section";

interface Props {
  candidacy: CandidacyDTO,
  setCandidacyStatus: (candidacy: CandidacyDTO, status: string) => void,
}


class CandidacySummary extends React.Component<Props>{

  actions = () => {
    const candidacy = this.props.candidacy;


    if (candidacy.status === "pending") {
      return (
        <>
          <Button variant="outlined" color="primary" onClick={() => this.props.setCandidacyStatus(candidacy, "accepted")}>
            Accept
          </Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.setCandidacyStatus(candidacy, "refused")}>
            Reject
          </Button>
        </>
      )
    }

    if (candidacy.status === "accepted") {
      return (
        <>
          <Button variant="outlined" color="primary" onClick={() => this.props.setCandidacyStatus(candidacy, "kicked")}>
            Kick
          </Button>
        </>
      )
    }




    return (
      <Button variant="outlined" color="primary" onClick={() => this.props.setCandidacyStatus(candidacy, "accepted")}>
        Accept
      </Button>
    )
  }

  render() {

    const style = {
      marginTop: "8px",
      border: "1px solid gray",
      padding: "8px"
    }

    return (
      <Section key={this.props.candidacy.id} style={style}>
        <FlexSection style={{marginTop: "0px"}}>
          <p>@{this.props.candidacy.username}</p>
          <p>{this.props.candidacy.skills}</p>
        </FlexSection>

        <FlexSection>
          {this.props.candidacy.text}
        </FlexSection>

        <Section style={{marginTop: "16px", textAlign: "center"}}>{this.actions()}</Section>
      </Section>
    )
  }
}


export default connect((state: State) => ({
}),
(dispatch: ThunkDispatchArg) => ({
  setCandidacyStatus: (candidacy: CandidacyDTO, status: string) => dispatch(setCandidacyStatus(candidacy, status))
}))(CandidacySummary);
