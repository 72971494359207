import React from "react";
import {CandidacyDTO} from "../../../data/projects/types";
import Section from "../../../components/Section";
import CandidacySummary from "../../../components/CandidacySummary";
import PageLayout from "../../../components/PageLayout";

interface Props {
  candidacies: Array<CandidacyDTO> | null,
  type: string
}


export default function Candidacies(props: Props) {
  if (!props.candidacies) {
    return (
      <Section>
        <p>No {props.type.toLowerCase()} applications</p>
      </Section>
    )
  }

  return (
    <Section>
      <h4>{props.type} applications</h4>

      {props.candidacies.map((candidacy) => <CandidacySummary candidacy={candidacy}/>)}

    </Section>
  )
}

