import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {RouteComponentProps} from "react-router";
import Section from "../components/Section";
import {ProjectReaction} from "../data/projects/types";
import {OrderedProjectsList} from "../data/projects";



interface Props extends RouteComponentProps<{id: string}> {
  orderedList: OrderedProjectsList,
  fetchProject: (id: string) => void,
  react: (id: string, reaction: ProjectReaction) => void,
  setStatus: (id: string, status: string) => void,
}

interface ProjectState {
}

class ProjectDescription extends React.Component<Props, ProjectState> {
  constructor(props: Props) {
    super(props);
  }

  renderDescription = (htmlBody: string) => {
    return {
      __html: htmlBody
    };
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }


    return (
      <PageLayout title={project.name}>
        <div style={{width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "space-between"}} >
          <Section>
            <div dangerouslySetInnerHTML={this.renderDescription(project.description)} />
          </Section>
        </div>
      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
    orderedList: state.projects.orderedList
}),
(dispatch: ThunkDispatchArg) => ({
}))(ProjectDescription);
