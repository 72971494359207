import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../../store";
import PageLayout from "../../components/PageLayout";
import {Redirect, RouteComponentProps} from "react-router";
import {OrderedProjectsList} from "../../data/projects";
import {Button} from "@material-ui/core";
import Section from "../../components/Section";
import {fetchProject, fetchTeamCandidacies} from "../../data/projects/service";
import CandidacySummary from "../../components/CandidacySummary";
import Candidacies from "./components/Candidacies";



interface Props extends RouteComponentProps<{id: string, team_id: string}> {
  orderedList: OrderedProjectsList,
  fetchTeamCandidacies: (projectId: string, teamId: string) => void
}

interface TeamApplicationState {
}

class ManageTeam extends React.Component<Props, TeamApplicationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount(): void {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return;

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return;

    this.props.fetchTeamCandidacies(project.id, team.id);
  }

  onJoinTeamClicked = () => {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return;

    // const team = project.teams.filter((team) => team.id === this.props.match.params.team_id)[0];
    // if (!team)
    //   return;


  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project) {
      return (<div>Loading ...</div>);
    }

    if (!project.can_user_edit) {
      this.props.history.goBack();
      return null;
    }


    const team = project.ordered_teams[this.props.match.params.team_id]
    if (!team)
      return <div>Team not found</div>

    if (!team.candidacies) {
      return (<div>Applications Loading ...</div>);
    }

    return (
      <PageLayout title={project.name}>
        <div style={{fontSize: "1.4em", textAlign: "center", marginTop: "16px"}}>Management - {team.name}</div>

        <Candidacies type="Pending" candidacies={team.candidacies.pending} />
        <Candidacies type="Accepted" candidacies={team.candidacies.accepted} />
        <Candidacies type="Kicked" candidacies={team.candidacies.kicked} />
        <Candidacies type="Refused" candidacies={team.candidacies.refused} />



      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
    orderedList: state.projects.orderedList,
}),
(dispatch: ThunkDispatchArg) => ({
  fetchTeamCandidacies: (projectId: string, teamId: string) => dispatch(fetchTeamCandidacies(projectId, teamId)),
}))(ManageTeam);
