export const INDEX_ROUTE = "/";
export const AUTH_ROUTE = "/auth";

export const LANDING_ROUTE = "/landing"

export const PROJECTS_LIST_ROUTE = "/projects";
export const PROPOSE_PROJECT_ROUTE = "/projects/new";


export const PROJECT_BASE_ROUTE = "/project";
export const PROJECT_ROUTE = `${PROJECT_BASE_ROUTE}/:id`;
export const PROJECT_DESCRIPTION_ROUTE = `${PROJECT_BASE_ROUTE}/:id/description`;
export const PROJECT_EDITION_ROUTE = `${PROJECT_BASE_ROUTE}/:id/edition`;


export const DONATE_PI = `${PROJECT_ROUTE}/donate`;
export const VIEW_TEAM = `${PROJECT_ROUTE}/team`;

export const APPLY_TEAM = `${VIEW_TEAM}/:team_id/apply`;
export const MANAGE_TEAM = `${VIEW_TEAM}/:team_id/manage`;
export const SEE_TEAM = `${VIEW_TEAM}/:team_id/see`;


