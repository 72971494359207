import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {Route, RouteComponentProps} from "react-router";
import {OrderedProjectsList} from "../data/projects";
import {ProjectDTO, ProjectTeamDTO} from "../data/projects/types";
import FlexSection from "../components/FlexSection";
import {Button} from "@material-ui/core";
import ProjectTeamSummary from "../components/ProjectTeamSummary";
import {Link} from "react-router-dom";
import {PROJECT_BASE_ROUTE} from "../lib/routes";
import {UserDTO} from "../data/auth/types";
import Section from "../components/Section";



interface Props extends RouteComponentProps<{id: string}> {
  orderedList: OrderedProjectsList,
  currentUser: UserDTO | null,
}

interface ProjectState {
}

class ProjectTeam extends React.Component<Props, ProjectState> {
  constructor(props: Props) {
    super(props);

  }


  renderTeamApplicationStatusOrButton = (project: ProjectDTO, team: ProjectTeamDTO) => {
    if (!this.props.currentUser)
      return;

    if (project.can_user_edit) {
      return (
        <Link to={`${PROJECT_BASE_ROUTE}/${project.id}/team/${team.id}/manage`} style={{textDecoration: "none"}}>
          <Button variant="outlined" color="primary">Manage {team.name}</Button>
        </Link>
      )
    }


    const currentCandidacy = project.user_candidacies[team.id];

    if (!currentCandidacy) {
      return (
        <Link to={`${PROJECT_BASE_ROUTE}/${project.id}/team/${team.id}/apply`} style={{textDecoration: "none"}}>
          <Button variant="outlined" color="primary">Join {team.name}</Button>
        </Link>
      )
    }

    if (currentCandidacy.status === "Pending") {
      return (
        <div>Application pending ... </div>
      )
    }


    return (
      <div>
        {currentCandidacy.status}
      </div>
    )
  }

  renderTeam = (index: number, project: ProjectDTO, team: ProjectTeamDTO) => {
    return (
      <FlexSection>
        <div>
          <h3>{team.name}:</h3>
          <p>
            <ProjectTeamSummary team={team} project={project} />
          </p>
        </div>

        {this.renderTeamApplicationStatusOrButton(project, team)}

      </FlexSection>
    )
  }

  renderOwnCandidacies = (project: ProjectDTO) => {
    if (Object.keys(project.user_candidacies).length === 0) {
      return null;
    }

    return (
      <Section style={{borderTop: "1px solid gray", marginTop: "16px", paddingTop: "16px"}}>
        <h4 style={{marginBottom: "8px"}}>My applications / Roles</h4>

        {
          Object.keys(project.user_candidacies).map((id) => {
            const currentCandidacy = project.user_candidacies[id];

            return (
              <FlexSection style={{marginTop: "8px", border: "1px solid gray", padding: "8px"}} key={id}>
                <div>
                  <div style={{fontWeight: 600}}>
                    {project.ordered_teams[id].name}
                  </div>
                  <div>
                    {currentCandidacy.status}
                  </div>
                </div>

                <div>
                  <div>
                    {currentCandidacy.text}
                  </div>
                  <div>
                    <Link to={`${PROJECT_BASE_ROUTE}/${project.id}/team/${currentCandidacy.team_id}/apply`}>
                      Edit application
                    </Link>
                  </div>
                </div>

              </FlexSection>
            )
          })
        }


      </Section>
    )
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }

    const team_ids = Object.keys(project.ordered_teams);


    return (
      <PageLayout title={project.name}>
        <div style={{fontSize: "1.6em", textAlign: "center", marginTop: "16px"}}>{project.can_user_edit ? "Manage" : "Join"} team</div>

        {
          team_ids.map((id, index) => this.renderTeam(index, project, project.ordered_teams[id]))
        }


        {this.renderOwnCandidacies(project)}

      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  orderedList: state.projects.orderedList,
  currentUser: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
}))(ProjectTeam);
