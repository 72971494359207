import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const breakpoints = [600, 960, 1280, 1600];

export const colors = {
  // primary: "#8363d4",
  // secondary: "#fbb44a",
  primary: "#AF2F98",
  my_project: "#0000ff",
  secondary: "#fc8b3a",
  accent: '#FFA500',
  logoGold_light: '#ffeed8',
  deep: '#0A1128',
  primary_darker: "#6d0b18",
  xLightGray: '#f3f3f3',
  lightgray: "#e5e5ea",
  divider: "#e5e5ea",
  gray: '#b0b0bf',
  darkgray: '#242424',
  greenCTA: '#6aa84f',
};

export const styledComponentsTheme = {
  breakpoints: breakpoints.map(b => `${b}px`),
  colors,
};

export const muiTheme = createMuiTheme({
  palette: {
    primary: {main: colors.primary},
    secondary: {main: colors.secondary},
    divider: colors.divider,
  },
  breakpoints: {
    keys: [
      "xs",
      "sm",
      "md",
      "lg",
      "xl",
    ],
    values: {
      xs: 0,
      sm: breakpoints[0],
      md: breakpoints[1],
      lg: breakpoints[2],
      xl: breakpoints[3],
    }
  }
});
