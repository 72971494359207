import React from "react";
import FlexSection from "./FlexSection";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import {ProjectReaction} from "../data/projects/types";
import {colors} from "../theme";

interface Props {
  data: {
    up: number,
    down: number,
  },
  onReacted?: (type: ProjectReaction) => void,
  myReaction: number,
}


export default function Thumbs(props: Props) {

  const clicked = (type: ProjectReaction) => {
    if (!props.onReacted)
      return;

    props.onReacted(type);
  }

  return (
    <div>
      <FlexSection style={{marginTop: 0}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

          <div style={{marginRight: '32px'}} onClick={() => clicked(1)}>
            <div style={{textAlign: "center", color: props.myReaction === 1 ? "#c09dd2" : colors.gray}}><ThumbUpIcon /></div>
            <div style={{textAlign: "center"}}>{props.data.up}</div>
          </div>

          <div  onClick={() => clicked(-1)}>
            <div style={{textAlign: "center", color: props.myReaction === -1 ? "#c09dd2" : colors.gray}}><ThumbDownIcon /></div>
            <div style={{textAlign: "center"}}>{props.data.down}</div>
          </div>

        </div>

      </FlexSection>


    </div>
  )
}

