import React from "react";

interface Props {
  placeholder: string,
  onChange: (value: number) => void,
  value: number,
}


export default function PiInputField(props: Props) {
  const onChange = (e: any) => {
    return props.onChange(parseFloat(e.target.value));
  }


  let style: React.CSSProperties = {
    border: "1px solid gray",
    width: "100%",
    padding: "4px",
    fontSize: "1em",
    textAlign: "left",
    textAnchor: "start",
  }


  // By default, the component is a normal text field.
  return <input type="number" min={0} step={0.0001} style={style} placeholder={props.placeholder} onChange={onChange} value={props.value}/>;
}

