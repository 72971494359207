import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {RouteComponentProps} from "react-router";
import {OrderedProjectsList} from "../data/projects";
import {donationPerformed, fetchProject} from "../data/projects/service";
import {Button} from "@material-ui/core";
import Section from "../components/Section";
import PiInputField from "../components/PiInputField";
import {PiNetworkClient} from "../data/auth";


interface Props extends RouteComponentProps<{id: string}> {
  orderedList: OrderedProjectsList,
  fetchProject: (id: string) => void,
  donationPerformed: (transferUuid: string, projectId: string) => void,
}

interface DonatePiState {
  donatePiValue: number,
}

class DonatePi extends React.Component<Props, DonatePiState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      donatePiValue: 0,
    }

  }

  donate = async () => {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) {
      return;
    }

    try {
      const transferRequest = await PiNetworkClient.RequestTransfer(this.state.donatePiValue, `Funding to ${project.name}`)
      this.props.donationPerformed(transferRequest.uuid, project.id);
    } catch(err) {
      // Technical problem (eg network failure). Please try again
    }
  }

  componentDidMount(): void {
    this.props.fetchProject(this.props.match.params.id);
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }


    return (
      <PageLayout title={project.name}>
        <div style={{width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", marginTop: "32px"}} >
          <Section>
            Donate Pi to help make this project happen. By donating, you support the project more than expressing your opinion in upvotes or downvotes.  Don’t worry. If the project does not happen, you can have it returned or transferred to another project.
          </Section>

          <Section>
            <PiInputField placeholder="Donate Pi" onChange={(value) => this.setState({donatePiValue: value})} value={this.state.donatePiValue} />
            <div style={{textAlign: "center", marginTop: "32px"}}>
              <Button variant="contained" color="secondary" onClick={this.donate}>Donate</Button>
            </div>
          </Section>


          <Section>
            Donated to this project so far: {project.financing} PI
          </Section>




        </div>
      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  orderedList: state.projects.orderedList
}),
(dispatch: ThunkDispatchArg) => ({
  fetchProject: (id: string) => dispatch(fetchProject(id)),
  donationPerformed: (transferUuid: string, projectId: string) => dispatch(donationPerformed(transferUuid, projectId))
}))(DonatePi);
