import React from "react";
import FlexSection from "../../../components/FlexSection";
import {ProjectDTO, ProjectReaction, ProjectTeamDTO} from "../../../data/projects/types";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {PROJECT_BASE_ROUTE} from "../../../lib/routes";
import ProjectTeamSummary from "../../../components/ProjectTeamSummary";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../../../store";
import {fetchProject, react, setStatus} from "../../../data/projects/service";
import {UserDTO} from "../../../data/auth/types";

interface Props {
  project: ProjectDTO,
  currentUser: UserDTO | null,
}


class Thumbs extends React.Component<Props>{

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    const team_ids = Object.keys(this.props.project.ordered_teams);


    return (
      <div>
        <FlexSection style={{marginTop: 0}}>
          <div>
            {
              team_ids.map((team_id: string, teamIndex: number) => {
                const team = this.props.project.ordered_teams[team_id];
                let backgroundColor = "white";

                const teamCandidacy = this.props.project.user_candidacies[team_id];
                if (teamCandidacy && teamCandidacy.status === "accepted") {
                  backgroundColor = "#fff1c0"
                }

                return (
                  <div key={teamIndex} style={{backgroundColor: backgroundColor, padding: "4px 0"}}>
                    {team.name}:
                    <ProjectTeamSummary team={team} project={this.props.project} />
                  </div>
                )
              })
            }
          </div>

          <div>
            <Link to={`${PROJECT_BASE_ROUTE}/${this.props.project.id}/team`} style={{textDecoration: "none"}}>
              <Button color="primary" variant="outlined">
                {this.props.project.can_user_edit ? "Manage " : "Join "} Team
              </Button>
            </Link>
          </div>
        </FlexSection>
      </div>
    )
  }
}

export default connect((state: State) => ({
  currentUser: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
}))(Thumbs);
