import React from "react";

interface Props {
  children: React.ReactNode,
  style?: React.CSSProperties,

}


export default function FlexSection(props: Props) {
  let style = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "32px",
  } as React.CSSProperties;

  if (props.style) {
    style = {...style, ...props.style}
  }

  return (
    <div style={style}>
      {props.children}
    </div>
  )
}
