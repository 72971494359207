import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {RouteComponentProps} from "react-router";
import {authUser} from "../data/auth/service";
import {UserDTO} from "../data/auth/types";
import {LANDING_ROUTE} from "../lib/routes";



interface Props extends RouteComponentProps<{id: string}> {
  user: UserDTO | null,
  authUser: () => void,
}

interface ProjectState {
}

class Auth extends React.Component<Props, ProjectState> {
  constructor(props: Props) {
    super(props);

  }

  async componentDidMount(){
    await this.props.authUser();
    this.props.history.replace(LANDING_ROUTE);
  }

  render() {

    return (
      <PageLayout horizontalPadding={8} verticalPadding={4}>
        <p>Authenticating with Pi Network ...</p>
      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  user: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
  authUser: () => dispatch(authUser())
}))(Auth);
