import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";


interface Props {
  title?: string,
  children: React.ReactNode,
  horizontalPadding?: number,
  verticalPadding?: number,
  backgroundColor?: string,
}


class PageLayout extends React.Component<Props> {
  render() {
    console.log(this.props.horizontalPadding, !!this.props.horizontalPadding)

    const horizontalPadding = (this.props.horizontalPadding || this.props.horizontalPadding === 0) ? this.props.horizontalPadding : 16;
    const verticalPadding = (this.props.verticalPadding || this.props.verticalPadding === 0) ? this.props.verticalPadding : 16;

    return (
      <div style={{
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: "100%",
        backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "white",
      }}>
        {
          this.props.title &&
            <h2 style={{textAlign: "center"}}>
              {this.props.title}
            </h2>
        }
        <div style={{flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default connect((state: State) => ({
}),
(dispatch: ThunkDispatchArg) => ({
}))(PageLayout);
