import React from "react";
import {connect} from "react-redux";
import {ProjectDTO} from "../data/projects/types";
import FlexSection from "./FlexSection";
import {Button} from "@material-ui/core";
import {State, ThunkDispatchArg} from "../store";
import {setStatus} from "../data/projects/service";
import {UserDTO} from "../data/auth/types";
import Section from "./Section";

interface Props {
  project: ProjectDTO,
  setStatus: (id: string, status: string) => void,
  currentUser: UserDTO | null,
}

class ProjectModeration extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }


  statusSwitchClicked = (status: string) => {
    this.props.setStatus(this.props.project.id, status)
  }

  render() {
    const { project, currentUser } = this.props;

    if (!currentUser || !project || currentUser.roles.indexOf("core_team") === -1) {
      return null;
    }

    let buttons = <div/>;

    if (project.status === "new" || project.status === "updated") {
      buttons = (
        <div>
          <Button color="primary" variant="outlined" onClick={() => this.statusSwitchClicked("published")}>
            Publish
          </Button>
          <Button color="primary" variant="outlined" onClick={() => this.statusSwitchClicked("not_published")}>
            Refuse
          </Button>
        </div>
      )
    }

    if (project.status === "published") {
      buttons = (
        <Button color="primary" variant="outlined" onClick={() => this.statusSwitchClicked("not_published")}>
          Unpublish
        </Button>

      )
    }

    if (project.status === "not_published") {
      buttons = (
        <>
          <Button color="primary" variant="outlined" onClick={() => this.statusSwitchClicked("published")}>
            Publish
          </Button>
          <Button color="primary" variant="outlined" onClick={() => this.statusSwitchClicked("new")}>
            re open review
          </Button>
        </>
      )
    }


    return (
      <Section style={{marginTop: "16px", borderTop: "1px solid gray"}}>
        <Section>
          <h4>Core team only:</h4>
          <p>Type {project.type}</p>
        </Section>

        <FlexSection>
          <h4>User will {!project.will_user_build_app ? "NOT" : ""} contribute building the app</h4>

          {
            project.will_user_build_app &&
              <p>Support: {project.user_support}</p>
          }
        </FlexSection>

        {
          project.will_user_build_app &&
            <Section>
              <h4>User plan</h4>
              <p>{project.user_plan}</p>
            </Section>
        }



        <FlexSection>
          <div>Project status: <strong>{project.status.toUpperCase()}</strong></div>
          {buttons}
        </FlexSection>

        <Section  style={{textAlign: "center"}}>
        </Section>
      </Section>
    )
  }
}

export default connect((state: State) => ({
  currentUser: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
  setStatus: (id: string, status: string) => dispatch(setStatus(id, status))
}))(ProjectModeration);
