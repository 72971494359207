import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../store";
import PageLayout from "../components/PageLayout";
import {RouteComponentProps} from "react-router";
import {OrderedProjectsList} from "../data/projects";
import Section from "../components/Section";
import {fetchTeamCandidacies} from "../data/projects/service";
import {UserDTO} from "../data/auth/types";

interface Props extends RouteComponentProps<{id: string, team_id: string}> {
  orderedList: OrderedProjectsList,
  fetchTeamCandidacies: (projectId: string, teamId: string) => void,
  currentUser: UserDTO | null,
}

interface TeamApplicationState {
}

class SeeTeam extends React.Component<Props, TeamApplicationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount(): void {
    const project = this.props.orderedList[this.props.match.params.id];
    if (!project)
      return;

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return;

    this.props.fetchTeamCandidacies(project.id, team.id);
  }

  render() {
    const project = this.props.orderedList[this.props.match.params.id];

    if (!project) { // TODO
      return (<div>Loading ...</div>);
    }

    const team = project.ordered_teams[this.props.match.params.team_id];
    if (!team)
      return <div>Team not found</div>

    if (!team.candidacies) {
      return (<div>Team Loading ...</div>);
    }

    return (
      <PageLayout title={project.name}>
        <div style={{fontSize: "1.4em", textAlign: "center", marginTop: "16px"}}>Application - {team.name}</div>

        <Section>
          <h4>{team.candidacies.accepted.length} {team.name}:</h4>
        </Section>

        <Section style={{marginTop: "0px"}}>
          {
            team.candidacies.accepted.map(candidacy => {
              let backgroundColor = "white";
              if (this.props.currentUser && this.props.currentUser.id === candidacy.user_id) {
                backgroundColor = "#fff1c0"
              }
              return (
                <Section style={{marginTop: "8px", backgroundColor: backgroundColor, padding: "4px 0"}} key={candidacy.id}>
                  @{candidacy.username} - {candidacy.skills}
                </Section>
              )
            })
          }
        </Section>


      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  orderedList: state.projects.orderedList,
  currentUser: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
  fetchTeamCandidacies: (projectId: string, teamId: string) => dispatch(fetchTeamCandidacies(projectId, teamId)),
}))(SeeTeam);
