import React from "react";
import {ProjectDTO, ProjectTeamDTO} from "../data/projects/types";
import {Link} from "react-router-dom";
import {PROJECT_BASE_ROUTE} from "../lib/routes";

interface Props {
  team: ProjectTeamDTO,
  project: ProjectDTO,
}


export default function ProjectTeamSummary(props: Props) {

  return (
    <Link to={`${PROJECT_BASE_ROUTE}/${props.project.id}/team/${props.team.id}/see`}>
      <>
        {
          props.team.users.slice(0, 2).map((user, userIndex) => {
            return (<span key={userIndex}>{userIndex !== 0 ? "," : ""}{" "}@{user.username}</span>)
          })
        }
      </>

      {
        props.team.users_count > 2 &&
          <>
            {", "}
            <span style={{color: "black", textDecoration: "underline"}}>
              + {props.team.users_count - 2} others
            </span>
          </>
      }
    </Link>
  )
}

