import React from 'react';
import {Provider} from "react-redux";
import {store} from "./store";
import {MuiThemeProvider, ThemeProvider } from '@material-ui/core';
import {muiTheme, styledComponentsTheme} from "./theme";
import Routes from "./components/Routes";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={styledComponentsTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <Routes />
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
