import {ProjectDTO, TeamCandidaciesDTO} from "./types";

export type OrderedProjectsList = {[id: string]: ProjectDTO};

export type ProjectsListResponse = {
  published: Array<ProjectDTO>,
  to_review: Array<ProjectDTO>,
  not_published: Array<ProjectDTO>,
}

export interface ProjectsState {
  list: ProjectsListResponse,
  orderedList: OrderedProjectsList,
}

const initialState: ProjectsState = {
  list: {
    published: [],
    to_review: [],
    not_published: [],
  },
  orderedList: {},
}


export const RECEIVED_PROJECT_DETAILS = "incubator/project/details";
export const RECEIVED_PROJECTS_LIST = "incubator/projects/list";
export const RECEIVED_TEAM_CANDIDACIES = "incubator/team/candidacies";

interface ReceivedProjectDetailsAction {
  type: typeof RECEIVED_PROJECT_DETAILS,
  dto: ProjectDTO,
}

interface ReceivedProjectsListAction {
  type: typeof RECEIVED_PROJECTS_LIST,
  list: ProjectsListResponse,
}

interface ReceivedTeamCandidacies {
  type: typeof RECEIVED_TEAM_CANDIDACIES,
  response: TeamCandidaciesDTO,
  projectId: string,
  teamId: string,
}




export type ProjectsAction = ReceivedProjectDetailsAction | ReceivedProjectsListAction | ReceivedTeamCandidacies;

export default function projectsReducer(state: ProjectsState = initialState, action: ProjectsAction): ProjectsState {
  switch (action.type) {
    case RECEIVED_PROJECT_DETAILS:
      return saveProjectDetails(state, action.dto);
    case RECEIVED_PROJECTS_LIST:
      return {
        ...state,
        list: action.list
      };
    case RECEIVED_TEAM_CANDIDACIES:
      return saveTeamCandidacies(state, action.response, action.projectId, action.teamId);
    default:
      return state;
  }
}

const saveProjectDetails = (state: ProjectsState, dto: ProjectDTO) => {
  let newOrderedProjectsList = {...state.orderedList}
  newOrderedProjectsList[dto.id] = dto
  return {
    ...state,
    orderedList: newOrderedProjectsList
  }
}

const saveTeamCandidacies = (state: ProjectsState,   response: TeamCandidaciesDTO, projectId: string, teamId: string): ProjectsState => {

  let newOrderedProjectsList = {...state.orderedList};

  newOrderedProjectsList[projectId].ordered_teams[teamId].candidacies = response;

  return {
    ...state,
    orderedList: newOrderedProjectsList
  }

  return state;
}




















