import {CandidacyDTO, ProjectDTO, ProjectReaction} from "./types";
import {ThunkResult} from "../../store";
import {get, post} from "../../lib/api";
import {RECEIVED_PROJECT_DETAILS, RECEIVED_PROJECTS_LIST, RECEIVED_TEAM_CANDIDACIES} from "./index";




export const fetchProject = (id: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    const project = await get(`/projects/${id}`);
    dispatch({type: RECEIVED_PROJECT_DETAILS, dto: project})
  }
}

export const fetchProjectsList = (): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    const projectsList = await get(`/projects`);
    dispatch({type: RECEIVED_PROJECTS_LIST, list: projectsList})
  }
}

export const donationPerformed = (transferRequestUuid: string, projectId: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await post(`/projects/${projectId}/donate`, {transfer_uuid: transferRequestUuid});
      dispatch({type: RECEIVED_PROJECT_DETAILS, dto: response.project})
    } catch (e) {
       // TODO
    }
  }
}

export const react = (projectId: string, reaction: ProjectReaction): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await post(`/projects/${projectId}/react`, {reaction: reaction});
      dispatch({type: RECEIVED_PROJECT_DETAILS, dto: response.project})
    } catch (e) {
       // TODO
    }
  }
}

export const setStatus = (projectId: string, status: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await post(`/projects/${projectId}/update_status`, {status: status});
      dispatch({type: RECEIVED_PROJECT_DETAILS, dto: response.project})
    } catch (e) {
       // TODO
    }
  }
}

export const applyToTeam = (projectId: string, team_id: string, skills: string, candidacy: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await post(`/projects/${projectId}/team/${team_id}/apply`, {skills: skills, candidacy: candidacy});
      dispatch({type: RECEIVED_PROJECT_DETAILS, dto: response.project})
    } catch (e) {
      // TODO
    }
  }
}

export const fetchTeamCandidacies = (projectId: string, teamId: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await get(`/projects/${projectId}/team/${teamId}/candidacies`);
      dispatch({type: RECEIVED_TEAM_CANDIDACIES, response: response, projectId: projectId, teamId: teamId})
    } catch (e) {
      // TODO
    }
  }
}

export const setCandidacyStatus = (candidacy: CandidacyDTO, status: string): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await post(`/candidacies/${candidacy.id}/set_status`, {status: status});
      await dispatch(fetchProject(candidacy.project_id));
      dispatch({type: RECEIVED_TEAM_CANDIDACIES, response: response, projectId: candidacy.project_id, teamId: candidacy.team_id})
    } catch (e) {
      // TODO
    }
  }
}











