import React from "react";
import {connect} from "react-redux";
import {State, ThunkDispatchArg} from "../../store";
import PageLayout from "../../components/PageLayout";
import {RouteComponentProps} from "react-router";
import {fetchProjectsList} from "../../data/projects/service";
import {ProjectDTO} from "../../data/projects/types";
import ProjectSummary from "./components/ProjectSummary";
import {PROJECT_BASE_ROUTE, PROPOSE_PROJECT_ROUTE} from "../../lib/routes";
import {Button} from "@material-ui/core";
import {ProjectsListResponse} from "../../data/projects";
import Section from "../../components/Section";
import {PiNetworkClient} from "../../data/auth";
import FlexSection from "../../components/FlexSection";



interface Props extends RouteComponentProps<{id: string}> {
  list: ProjectsListResponse,
  fetchProjectsList: () => void,
}

interface ProjectState {
}

class ProjectsList extends React.Component<Props, ProjectState> {
  constructor(props: Props) {
    super(props);

  }

  componentDidMount(): void {
    this.props.fetchProjectsList();
  }

  renderPendingProjectsList = () => {
    if (this.props.list.to_review.length === 0) {
      return null;
    }

    return (
      <Section style={{marginTop: "8px"}}>
        <div style={{border: "1px solid #DFDFDF", textAlign: "center", padding: "4px 0", fontSize: "0.8em", fontWeight: "bold", position: "relative", width: "100%", backgroundColor: "white"}}>
          Pending review
        </div>


        {
          this.props.list.to_review.map((project, index) => <ProjectSummary key={index} project={project} onClick={() => this.props.history.push(`${PROJECT_BASE_ROUTE}/${project.id}`)}/>)
        }
      </Section>
    )
  }

  renderNotPublishedProjectsList = () => {
    if (this.props.list.not_published.length === 0) {
      return null;
    }

    return (
      <Section style={{marginTop: "8px"}}>
        <div style={{border: "1px solid #DFDFDF", textAlign: "center", padding: "4px 0", fontSize: "0.8em", fontWeight: "bold", position: "relative", width: "100%", backgroundColor: "white"}}>
          Revisions Needed
        </div>


        {
          this.props.list.not_published.map((project, index) => <ProjectSummary key={index} project={project} onClick={() => this.props.history.push(`${PROJECT_BASE_ROUTE}/${project.id}`)}/>)
        }
      </Section>
    )
  }

  render() {
    return (
      <PageLayout horizontalPadding={0} verticalPadding={4} backgroundColor="#EFEFEF">

        <div style={{border: "1px solid #DFDFDF", textAlign: "center", padding: "4px 0", fontSize: "0.8em", fontWeight: "bold", position: "relative", width: "100%", backgroundColor: "white"}}>
          Proposed Pi Apps
        </div>


        {
          this.props.list.published.map((project, index) => <ProjectSummary key={index} project={project} onClick={() => this.props.history.push(`${PROJECT_BASE_ROUTE}/${project.id}`)}/>)
        }

        {this.renderPendingProjectsList()}
        {this.renderNotPublishedProjectsList()}


        <div style={{display: "block", height: "56px"}} />

        <div style={{textAlign: "center", position: "fixed", bottom: 0, padding: "8px 0", backgroundColor: "white", width: "100%", display: "flex", justifyContent: "space-around"}}>
          <Button variant="outlined" color="primary" onClick={() => this.props.history.push(PROPOSE_PROJECT_ROUTE)}>Propose project</Button>
          <Button variant="outlined" color="primary" onClick={() => PiNetworkClient.OpenAppConversation()}>
            Chat Room
          </Button>
        </div>


      </PageLayout>
    )
  }
}

export default connect((state: State) => ({
  list: state.projects.list,
  user: state.auth.user,
}),
(dispatch: ThunkDispatchArg) => ({
  fetchProjectsList: () => dispatch(fetchProjectsList()),
}))(ProjectsList);
