import {UserDTO} from "./types";

// @ts-ignore
export const PiNetworkClient: PiNetworkInstance = window.PiNetwork;



export interface AuthState {
  user: UserDTO | null,
}

const initialState: AuthState = {
  user: null
}



export const RECEIVED_USER = "incubator/auth/user_received"

export type ReceivedUserAction = {
  type: typeof RECEIVED_USER,
  user: UserDTO
}



export type AuthAction = ReceivedUserAction;

export default function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
  switch(action.type) {
    case RECEIVED_USER:
      return {
        ...state,
        user: action.user
      }
    default:
      return state;
  }
}


