import axios from "axios";
import {RequestOptions} from "https";
import {store} from "../../store";

// TODO: Get it from environment variable
export const BACKEND_URL = "https://backend.incubator.socialchainapp.com";
// export const BACKEND_URL = "http://localhost:8081";


const req = axios.create({
  baseURL: BACKEND_URL,
  timeout: 20000,
});


function getOptions() {
  const state = store.getState();

  let headers = {};

  if (state.auth.user)
    headers = { "authorization": `Bearer ${state.auth.user.access_token}` };

  return {headers: headers}
}

// TODO: Actual error handling
const handleError = (err: any) => {
  console.log("Request error: ", err);
}

export const get = (url: string): Promise<any> => {
  return new Promise<any>( async(resolve) => {
    try {
      const res = await req.get(url, getOptions());
      return resolve(res.data);
    } catch (e) {
      handleError(e);
    }
  })
}

export const post = (url: string, body: any = {}): Promise<any> => {
  return new Promise<any>( async(resolve) => {
    try {
      const res = await req.post(url, body, getOptions());
      return resolve(res.data);
    } catch (e) {
      handleError(e);
    }
  })
}

export const put = (url: string, body: any = {}): Promise<any> => {
  return new Promise<any>( async(resolve) => {
    try {
      const res = await req.put(url, body, getOptions());
      return resolve(res.data);
    } catch (e) {
      handleError(e);
    }
  })
}




