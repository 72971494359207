import React from "react";
import {Redirect, Switch} from "react-router";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {
  APPLY_TEAM,
  AUTH_ROUTE,
  DONATE_PI,
  INDEX_ROUTE, MANAGE_TEAM, PROJECT_DESCRIPTION_ROUTE, PROJECT_EDITION_ROUTE,
  PROJECT_ROUTE,
  PROJECTS_LIST_ROUTE,
  LANDING_ROUTE,
  PROPOSE_PROJECT_ROUTE, SEE_TEAM, VIEW_TEAM
} from "../../lib/routes";
import Landing from "../../pages/Landing";
import ProposeProject from "../../pages/ProposeProject";
import Project from "../../pages/Project";
import DonatePi from "../../pages/DonatePi";
import ProjectsList from "../../pages/ProjectsList";
import Auth from "../../pages/Auth";
import ProjectTeam from "../../pages/ProjectTeam";
import TeamApplication from "../../pages/TeamApplication";
import ManageTeam from "../../pages/ManageTeam";
import SeeTeam from "../../pages/SeeTeam";
import ProjectDescription from "../../pages/ProjectDescription";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path={LANDING_ROUTE} component={Landing} />
        <Route path={PROPOSE_PROJECT_ROUTE} component={ProposeProject} />
        <Route path={DONATE_PI} component={DonatePi} />
        <Route path={APPLY_TEAM} component={TeamApplication} />
        <Route path={MANAGE_TEAM} component={ManageTeam} />
        <Route path={SEE_TEAM} component={SeeTeam} />
        <Route path={VIEW_TEAM} component={ProjectTeam} />
        <Route path={PROJECT_DESCRIPTION_ROUTE} component={ProjectDescription} />
        <Route path={PROJECT_EDITION_ROUTE} component={ProposeProject} />
        <Route path={PROJECT_ROUTE} component={Project} />
        <Route path={PROJECTS_LIST_ROUTE} component={ProjectsList} />
        <Route path={AUTH_ROUTE} component={Auth} />
        <Route path={INDEX_ROUTE}><Redirect to={AUTH_ROUTE} /></Route>
      </Switch>
    </Router>
  )
}

