import React from "react";
import {ProjectDTO} from "../../../data/projects/types";
import FlexSection from "../../../components/FlexSection";
import Thumbs from "../../../components/Thumbs";
import Section from "../../../components/Section";
import {colors} from "../../../theme";

interface Props {
  key: number,
  project: ProjectDTO,
  onClick?: () => void
}


export default function ProjectSummary(props: Props) {

  const style: React.CSSProperties = {
    width: "100%",
    margin: "4px 0",
    padding: "8px",
    border: "1px solid #DFDFDF",
    backgroundColor: props.project.is_my_project ? colors.my_project : "white",
    color: props.project.is_my_project ? colors.xLightGray : "black",
  }

  const financingColor = props.project.is_my_project ? colors.xLightGray : colors.primary;

  return (
    <div style={style} key={props.key} onClick={() => props.onClick ? props.onClick() : {}}>
      <FlexSection style={{marginTop: 0}}>
        <div>
          <h4 style={{fontWeight: 500}}>
            {props.project.name}
          </h4>
          <div style={{marginBottom: "8px"}}>
            {props.project.summary}
          </div>
          <p style={{color: financingColor, fontSize: "0.8em"}}>{props.project.financing} π</p>
        </div>

        <div>
          <Thumbs data={{up: props.project.votes.up, down: props.project.votes.down}} myReaction={0} />
        </div>

      </FlexSection>
    </div>
  )
}

