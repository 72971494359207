import {ThunkResult} from "../../store";
import {PiNetworkClient, RECEIVED_USER} from "./index";
import {post} from "../../lib/api";

export const authUser = (): ThunkResult<Promise<any>> => {
  return async (dispatch) => {
    const piUser = await PiNetworkClient.Authenticate();

    try {
      const authenticationResponse = await post('/users/authenticate', {user: piUser});
      dispatch({type: RECEIVED_USER, user: authenticationResponse.user});
    } catch (e) {
      alert("UNAUTHENTICATED"); // TODO
    }
  }
}
